import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import "../CSS/layout.css";
import logo from "../Assets/Logo.png";
import { Link } from "react-router-dom";

const Layout = ({ children }) => {
  return (
    <div className="layout-container mr-darcy">
      
      {/* <main className="layout-main">{children}</main> */}
    </div>
  )
}

export default Layout;