import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Routes } from 'react-router';
import { HelmetProvider } from 'react-helmet-async';

import './CSS/layout.css'

// import BlogPosts from './Components/BlogPosts'
import BlogPosts from './Components/BlogPosts.jsx';
import BlogPost from './Components/BlogPost.jsx';
import BlogByCategory from './Components/BlogByCategory.jsx';

// import Layout from './Components/Layout';
import LandingPage from './Pages/LandingPage.js';
import Disclaimer from './Pages/Disclaimer.js';
import PrivacyPolicy from './Pages/PrivacyPolicy.js';
import Terms from './Pages/TermsPage.js';
import AboutPage from './Pages/AboutPage.js';
import AuthorPage from './Pages/AuthorPage.js';
import ContactPage from './Pages/ContactPage.js';
import Navigation from './Components/Nav.jsx'
import Footer from './Components/Footer.jsx'

function App() {

  return (
    <div className="App">
      <div>
        <HelmetProvider>
          <Router>
            <header className="App-header">
              <Navigation />
            </header>  
            <Routes>
              <Route path='/' element={<LandingPage />} />
              <Route path="/blog" element={<LandingPage />}/>
              <Route path='/blog-post/:slug' element={<BlogPost />} />
              <Route path='/category/:category' element={<BlogByCategory />} />
              <Route path='/author' element={<AuthorPage />} />
              <Route path='/about' element={<AboutPage />} />
              <Route path='/contact' element={<ContactPage />} />
              <Route path='/disclaimer' element={<Disclaimer />} />
              <Route path='/privacypolicy' element={<PrivacyPolicy />} />
              <Route path='/termsandconditions' element={<Terms />} />
            </Routes>
          </Router>
        </HelmetProvider>
      </div>
      <Footer />
    </div>
  );
}

export default App;