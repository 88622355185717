import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import "../CSS/layout.css";
import logo from "../Assets/Logo.png";
import { Link } from "react-router-dom";

const Navigation = ({ children }) => {
  return (
    <div className="layout-container mr-darcy">
      <header className="layout-header">
        <Navbar className="justify-content-center" collapseOnSelect expand="lg" bg="dark" variant="dark">
          <div className="d-flex flex-grow-1 align-items-center">
            <div className="logo-container">
              <img className="logo" src={logo} alt="Logo" />
            </div>
            <Link to="/">
              <Navbar.Brand className="navbar-brand">Gothic Getaways</Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto nav-dropdown">
                <NavDropdown title="Type of Trip" id="collapsible-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/category/deluxe">Deluxe</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/category/inexpensive">Inexpensive</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/category/group">Group</NavDropdown.Item>
                  {/* Add more dropdown items here */}
                </NavDropdown>
                <NavDropdown title="Destinations" id="collapsible-nav-dropdown">
                  <NavDropdown.Item as={Link}to="/category/historical">Historical Haunts</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/category/cemeteries">Cemeteries</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/category/film">Film Sites</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/category/haunted-attractions">Haunted Attractions</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/category/where-to-stay">Paranormal Accomodations</NavDropdown.Item>
                  {/* Add more dropdown items here */}
                </NavDropdown>
                <NavDropdown title="Activities" id="collapsible-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/category/tours">Tours</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/category/explore">Explore</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/category/shopping">Shopping</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/category/dining">Dining</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/category/haunted-attractions">Haunted Attractions</NavDropdown.Item>
                  {/* Add more dropdown items here */}
                </NavDropdown>
                {/* Add more Nav and NavDropdown components as needed */}
              </Nav>
              <Nav className="nav-link-container">
              <Link to="/about" className="nav-link">About</Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </header>
    </div>
)}
export default Navigation