import * as React from "react"
import { useState } from "react"
import "../CSS/InfoPages.css"
import SteveSalem from '../Assets/SteveSalem.png'

const AboutPage = () => {
  const [floatDirection, setFloatDirection] = useState('left');

  const toggleFloatDirection = () => {
    setFloatDirection(floatDirection === 'left' ? 'right' : 'left');
  };
  return(
    <div className="about-page">
      
      <h1 className="about-title">Gothic Getaways</h1>
      
      <p className="about-text">Looking for the perfect paranormal vacation package but afraid you'll spend hours researching and coordinating an itinerary? Seek no further eerie traveler!</p>

      <span></span>
      
      <p className="about-text">Gothic Getaways sprouted from a deep-rooted fascination with the otherworldly, the obscure, and the uncanny. We are a dedicated team of supernatural enthusiasts, seasoned travellers, and storytellers, who aspire to bridge the gap between the known and the unknown. Our mission is to shed light on the world's most haunted, esoteric, and horror-inducing travel destinations that are hidden in the fabric of our reality. Join us as we peel back the veil of the ordinary and step into a realm where myths, legends, and ghost stories come alive..</p>
      <div className="welcome">
        <div className="welcome-text">
          
          <h1 className="about-author">About The Author</h1> 
          
          <h4>I'm Steve, your humble ghoulish guide</h4>

          <p>From my earliest memories, I've always been enchanted by the macabre and everything paranormal. Having grown up in a family that passionately celebrated Halloween and regularly watched movies like Beetlejuice and Poltergeist, some might say I was born to write this blog.</p>
          <img 
            src={SteveSalem} 
            alt="Logo" 
            className={`welcome-img ${floatDirection}`}
            onLoad={toggleFloatDirection}
          />

          <p>Today, I use this passion to help fellow esoteric explorers like you to discover the darker, more gothic side of travel. I've delved into haunted destinations all across the country, finding accommodations with just the right spectral charm. Allow me to help you have the paranormal adventure of your dreams (or favorite nightmares), without having to dig for the spooky content you crave!</p>

          <p>Join me, as we cross the veil and explore the mysteries that await.</p>
        </div>
      </div>
    </div>
    
  )
}

export default AboutPage