import * as React from "react"
import Layout from "../Components/Layout.jsx"
// import BlogPosts from "../components/BlogPosts"

const AuthorPage = () => (
  <Layout>
    <h1>About The Author</h1>

    <p>Hello, fellow explorers of the eerie and enchanting!

    Hello, fellow explorers of the eerie and enchanting! I'm, Steve, your humble, ghoulish guide.

    From my earliest memories, I've always been enchanted by the macabre and everything paranormal. Having grown up in a family that celebrated Halloween with a passion and loved watching movies like Beetlejuice and Poltergeist regularly, some might say I was born to write this blog.

    Today, I use this passion to help fellow explorers like you discover the darker, more gothic side of travel. I've delved into haunted destinations all across the country, finding accommodations with just the right spectral charm. Allow me to help you have the paranormal adventure of your dreams (or favorite nightmares), without having to dig for the spooky content you crave!

    Join me, as we cross the veil and explore the mysteries that await.</p>
  </Layout>
)

export default AuthorPage
