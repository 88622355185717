import React, { useState } from "react";
import emailjs from 'emailjs-com';
import '../CSS/InfoPages.css'

const ContactPage = () => {
    console.log("ContactPage is being rendered");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [messageSent, setMessageSent] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
    
        emailjs.send('service_2zt13nj', 'template_3a0gd77', { from_name: name, from_email: email, message: message }, 'zg8i6U_NCcXcfWf2w')
          .then((response) => {
               console.log('SUCCESS!', response.status, response.text);
               setMessageSent(true);  // Show the popup message

            // Hide the popup message after 5 seconds
            setTimeout(() => {
                setMessageSent(false);
            }, 3000);
               // Clear form
               setName("");
               setEmail("");
               setMessage("");
          }, (err) => {
               console.log('FAILED...', err);
          });
    };

    return (
        <div className="contact">
            <h1>Contact Us</h1>
            {messageSent && <div className="popup">Your message has been sent!</div>}
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <input
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <textarea
                    placeholder="Your Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default ContactPage;