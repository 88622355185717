import * as React from "react"
import { useState } from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Layout from "../Components/Layout.jsx"
import Footer from "../Components/Footer.jsx"
import '../CSS/layout.css'
import BlogPosts from "../Components/BlogPosts.jsx"
import BlogByCategory from "../Components/BlogByCategory.jsx";
import west from '../Assets/Regions/west.png'
import NewEngland from '../Assets/Regions/newengland.png'
import SouthEast from '../Assets/Regions/southeast.png'
import MidWest from '../Assets/Regions/midwest.png'
import logo from '../Assets/Logo.png'
import WideCemetery from '../Assets/WideCemetery.png'
import WideCemetery2 from '../Assets/WideCemetery2.png'
import SteveSalem from '../Assets/SteveSalem.png'

const LandingPage = () => {
  const [floatDirection, setFloatDirection] = useState('left');

  const toggleFloatDirection = () => {
    setFloatDirection(floatDirection === 'left' ? 'right' : 'left');
  };

  return(
  <div>
    <Helmet>
      <title>Gothic Getaways</title>
      <meta name="description" content="The #1 Travel Site for haunted retreats, chilling tours, and other spine-tingling adventures" />
      <link rel="canonical" href="https://www.gothicgetaways.com/" />
      <meta property="og:title" content="Gothic Getaways" />
      <meta property="og:description" content="The #1 Travel Site for haunted retreats, chilling tours, and other spine-tingling adventures" />
      <meta property="og:url" content="https://www.gothicgetaways.com/" />
      <meta property="og:type" content="website" />
      {/* Assuming you have a default image for the blog page */}
      <meta property="og:image" content="https://gothicgetaways.com/static/media/Logo.777ecc5d8060c5184beb.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627"/>
    </Helmet>
    <div className="banner m-0 px-2">
      <div className="container-fluid banner-overlay px-1">
        <div className="row">
          <div className="col banner-text text-center py-4">
            <h1 className="display-3 display-sm-4 display-md-5">Gothic Getaways</h1>
            <h4>The #1 Travel Site for haunted retreats, chilling tours, and other spine-tingling adventures</h4>
          </div>
        </div>
      </div>    
    </div>

    <div className="regions">
      <div className="regions-cards">
        <Link to="/category/west">
          <div className="west">
            <img src={west} alt="West" />
          </div>
        </Link>
        <Link to="/category/southeast">
          <div className="southeast">
            <img src={SouthEast} alt="South East" />
          </div>
        </Link>
        <Link to="/category/midwest">
          <div className="midwest">
            <img src={MidWest} alt="Mid West" />
          </div>
        </Link>
        <Link to="/category/new-england">
          <div className="new-england">
            <img src={NewEngland} alt="New England" />
          </div>
        </Link>
      </div>
    </div>
    <div className="landing-img">
      <img src={WideCemetery} alt="Wide Cemetery" />
    </div>
    <div className="welcome">
      <div className="welcome-text">
        <h1>Greetings!</h1> 
        
        <h4>I'm Steve, your humble ghoulish guide.</h4>

        <p className="welcome-blurb">From my earliest memories, I've always been enchanted by the macabre and everything paranormal. Having grown up in a family that passionately celebrated Halloween and regularly watched movies like Beetlejuice and Poltergeist, some might say I was born to write this blog.</p>
        <img 
          src={SteveSalem} 
          alt="Logo" 
          className={`welcome-img ${floatDirection}`}
          onLoad={toggleFloatDirection}
        />

        <p className="welcome-blurb">Today, I use this passion to help fellow esoteric explorers like you to discover the darker, more gothic side of travel. I've delved into haunted destinations all across the country, finding accommodations with just the right spectral charm. Allow me to help you have the paranormal adventure of your dreams (or favorite nightmares), without having to dig for the spooky content you crave!</p>

        <p className="welcome-blurb">Join me, as we cross the veil and explore the mysteries that await.</p>
      </div>
    </div>
    <div className="landing-img2">
      <img src={WideCemetery2} alt="Wide Cemetery" />
    </div>
    <div className="latest">
      <div className="latest-title">
        <h1>Latest Articles</h1>
      </div>
    </div>
    <BlogPosts />
  </div>
  )
}

export default LandingPage