import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import client from '../contentful.js'
import '../CSS/BlogPost.css'
import '../CSS/layout.css'
import { formatDate } from "./BlogPosts.jsx";

const BlogPost = () => {
  const { slug } = useParams(); // changed from id to slug
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const entries = await client.getEntries({
          content_type: 'blogPost',
          'fields.slug': slug,  // query by slug field
          limit: 1,
        });

        if (entries.items.length > 0) {
          // entries.items is an array, take the first element as our post
          setPost(entries.items[0]);
        } else {
          console.error(`Error: No blog post found for slug '${slug}'`);
        }
      } catch (error) {
        console.error('Error fetching blog post:', error);
      }
    };

    fetchPost();
  }, [slug]); // changed from id to slug

  console.log("Post:", post);
  if (!post) return <div>Loading...</div>;

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "Article",
    "headline": post.fields.title,
    "image": `http:${post.fields.featuredImage.fields.file.url}`,
    "datePublished": formatDate(post.fields.publishedDate),
    "author": [
      {
        "@type": "Person",
        "name": post.fields.author.fields.name,
        "url": "N/A"
      }
    ],
    "category": post.fields.category.map(category => ({
      "@type": "Entry",
      "title": category.fields.title,
      "url": "N/A"
    })),
    "description": post.fields.shortDescription,
  };

  function YourComponent() {
    const location = useLocation();
    const currentUrl = `${window.location.origin}${location.pathname}`;
  
    // Pass currentUrl to the component that uses Helmet
  }
  

  const options = (() => {
    let lastFloatDirection = 'left';
  
    return {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const { title, file } = node.data.target.fields;
          const { url } = file;
  
          if (file.contentType.includes('image')) {
            const currentFloatDirection = lastFloatDirection === 'left' ? 'right' : 'left';
            lastFloatDirection = currentFloatDirection;
            return <img src={url} alt={title} className={`floating-image ${currentFloatDirection}`} />;
          }
        },
        [INLINES.EMBEDDED_ASSET]: (node) => {
          const { title, file } = node.data.target.fields;
          const { url } = file;
  
          if (file.contentType.includes('image')) {
            const currentFloatDirection = lastFloatDirection === 'left' ? 'right' : 'left';
            lastFloatDirection = currentFloatDirection;
            return <img src={url} alt={title} className={`floating-image ${currentFloatDirection}`} />;
          }
        },
        [INLINES.HYPERLINK]: (node, children) => {
          return <a href={node.data.uri} target="_blank" rel="noopener noreferrer">{children}</a>
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
          const embeddedPost = node.data.target;
          return (
            <div className="reference-post">
              <h2>Click Here For More Info:</h2>
              <Link to={`/blog-post/${embeddedPost.sys.id}`} key={embeddedPost.sys.id} className="latest_card_link">
                <div className="latest_card">
                  <img
                    className="latest_card_image"
                    src={embeddedPost.fields.featuredImage.fields.file.url}
                    alt={embeddedPost.fields.title}
                  />
                  <div className="latest_card_content">
                    <p className="latest_card_title">{embeddedPost.fields.title}</p>
                    <div className="latest_author">
                      <div className="latest_author_image_wrapper">
                        <img
                          className="latest_author_image"
                          src={embeddedPost.fields.author.fields.avatar.fields.file.url}
                          alt={embeddedPost.fields.author.fields.name}
                        />
                      </div>
                      <p className="latest_author">{embeddedPost.fields.author.fields.name}</p>
                      <div className="latest_date">
                        <p>{formatDate(embeddedPost.fields.publishedDate)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        },
        [INLINES.EMBEDDED_ENTRY]: (node) => {
          // You can define your logic for inline embedded entries here
        },
      },
    };
  })();

  return (
    <div className="blog_post">
      <Helmet>
        <title>{post.fields.title}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={post.fields.shortDescription} />
        <meta property="og:title" content={post.fields.title} />
        <meta property="og:description" content={post.fields.shortDescription} />
        <meta property="og:image" content={`http:${post.fields.featuredImage.fields.file.url}`} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627"/>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="106825599129706" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8211973232545086"
        crossorigin="anonymous"></script>
      </Helmet>
      <div className="post_featured">
        <div className="post_featured_preview">
          <img
            className="post_featured_image"
            src={post.fields.featuredImage.fields.file.url}
            alt={post.fields.title}
          />
        </div>
      </div>
      <div className="blog_content">
        <div className="post_featured_content">
          <h1 className="post_featured_title">{post.fields.title}</h1>
          <div className="post_short_description">
            <p>{post.fields.shortDescription}</p>
          </div>
          <div className="post_details">
            <div className="post_featured_author">
              <div className="post_featured_author_image_wrapper">
                <img
                  className="post_featured_author_image"
                  src={post.fields.author.fields.avatar.fields.file.url}
                  alt={post.fields.author.fields.name}
                />
              </div>
              <p className="post_featured_author">{post.fields.author.fields.name}</p>
            </div>
            <div className="post_featured_date">
              <p>🕒<span></span> {formatDate(post.fields.publishedDate)}</p>
            </div>
            <p className="post_category">📁<span> </span>
              {post.fields.category.map((category, index) => 
                <span key={index}>
                  <Link to={`/category/${category.fields.slug}`}>
                    {category.fields.title}
                  </Link>
                  {index < post.fields.category.length - 1 ? ' | ' : ''}
                </span>
              )}
            </p>
          </div>

          <div className="disclosure">
            <p>Disclosure: This article contains affiliate links, meaning when you make a purchase, I earn a small commission. Affiliate links cost you nothing to use, and help keep my content free. It's a win-win for us both! For more info, see the Disclosure Policy</p>
          </div>
          <div className="post_divider">
            <p>~      ~      ~</p>
          </div>
        </div>
        {documentToReactComponents(post.fields.content, options)}
      </div>
      <div className="post_related_section">
        <div className="post_latest_section_title">
          <h1>Related Posts</h1>
        </div>
        {post.fields.relatedPosts && post.fields.relatedPosts.length > 0 && (
          <div className="latest_cards_container">
            {post.fields.relatedPosts.map(relatedPost => (
              <Link to={`/blog-post/${relatedPost.fields.slug}`} key={relatedPost.sys.id} className="latest_card_link">
                <div className="latest_card">
                  <img
                    className="latest_card_image"
                    src={relatedPost.fields.featuredImage.fields.file.url}
                    alt={relatedPost.fields.title}
                  />
                  <div className="latest_card_content">
                    <p className="latest_card_title">{relatedPost.fields.title}</p>
                    <div className="latest_author">
                      <div className="latest_author_image_wrapper">
                        <img
                          className="latest_author_image"
                          src={relatedPost.fields.author.fields.avatar.fields.file.url}
                          alt={relatedPost.fields.author.fields.name}
                        />
                      </div>
                      <p className="latest_author">{relatedPost.fields.author.fields.name}</p>
                      <div className="latest_date">
                        <p>{formatDate(relatedPost.fields.publishedDate)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPost;