import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import client from '../contentful.js'; 
import { formatDate } from './BlogPosts.jsx';
import "../CSS/Category.css"

const BlogByCategory = () => {
    const [posts, setPosts] = useState(null);
    const [category, setCategory] = useState(null); 
    const { category: categorySlug } = useParams();
  
    useEffect(() => {
      console.log("Current category slug: ", categorySlug);

      async function fetchPosts() {
        try {
          const categoryResponse = await client.getEntries({
            content_type: 'category',
            'fields.slug': categorySlug,
          });    
  
          if (categoryResponse.items.length === 0) {
            throw new Error(`Category with slug "${categorySlug}" not found.`);
          }
  
          const category = categoryResponse.items[0];
          setCategory(category); // save the category in state
  
          const postResponse = await client.getEntries({
            content_type: 'blogPost',
            'fields.category.sys.id': category.sys.id,
          });
  
          setPosts(postResponse.items);
        } catch (error) {
          console.error(error);
        }
      }
  
      fetchPosts();
    }, [categorySlug]); // depends on categorySlug
  
    if (!posts || !category) {
      return <div>Loading...</div>;
    }
    console.log('posts: ', posts)
    return (
        <div className="category-page">
            <div className="latest">
                <div className="category_section_title">
                    <h1>{category.fields.title}</h1>
                </div>
                <div className="latest_cards_container">
                    {posts.map((post) => (
                    <Link to={`/blog-post/${post.fields.slug}`} key={post.sys.id} className="latest_card_link">
                        <div className="latest_card">
                        <img
                            className="latest_card_image"
                            src={post.fields.featuredImage.fields.file.url}
                            alt={post.fields.title}
                        />
                        <div className="latest_card_content">
                            <p className="latest_card_title">{post.fields.title}</p>
                            <div className="latest_author">
                            <div className="latest_author_image_wrapper">
                                <img
                                className="latest_author_image"
                                src={post.fields.author.fields.avatar.fields.file.url}
                                alt={post.fields.author.fields.name}
                                />
                            </div>
                            <p className="latest_author_name">
                                {post.fields.author.fields.name}
                            </p>
                            <div className="latest_date">
                                <p>{formatDate(post.fields.publishedDate)}</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};
  
export default BlogByCategory;
