

const Footer = () => {
    return (
        <footer className="layout-footer">
            © {new Date().getFullYear()}, Gothic Getaways
            <div></div>
            <a href="/disclaimer"> Disclaimer </a>| <a href="/privacypolicy">Privacy Policy</a> | <a href="/termsandconditions">Terms & Conditions</a> | <a href="/contact">Contact Us</a>
        </footer>
    )
}

export default Footer