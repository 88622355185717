import * as React from "react"
import "../CSS/InfoPages.css"

const PrivacyPolicy = () => {
  return(
    <div className="privacy-policy">
        <h1>Privacy Policy</h1>
        <p>Welcome to our Paranormal Travel Blog. Your privacy is critically important to us, and we are committed to protecting it. This Privacy Policy outlines the type of information that is collected by us on our site, how we use and protect it. This Policy also includes an explanation of certain rights you possess regarding your personal information. Please read this Policy carefully.</p>

        <p>1. Information Collection</p>

        <p>1.1 Automatic Collection: As with most websites, when you visit our site, we automatically collect certain information about your device, including your IP address, browser type, the referring website, the pages you view, and the dates/times of your access. This information helps us improve the website and your experience.</p>

        <p>1.2 Information You Provide: When you subscribe to our newsletter, comment on our posts, or contact us directly, we may receive personal information, like your name, email address, and any other information you provide to us.</p>

        <p>2. Use of Information</p>

        <p>2.1 Providing Services: We use the information we collect to provide services to you, respond to your inquiries, and operate and maintain our website.</p>

        <p>2.2 Communication: If you've signed up for our email newsletter, we will send you updates about our posts and any other relevant information. You can opt-out at any time by clicking the unsubscribe link in the email.</p>

        <p>2.3 Improving Our Website: We may use the collected information to understand our users and make improvements to our website.</p>

        <p>3. Information Protection</p>

        <p>We implement a variety of security measures to maintain the safety of your personal information. Your personal information is stored behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems.</p>

        <p>4. Cookies</p>

        <p>We use "cookies" to enhance your experience with our site. Cookies are small files that we transfer to your computer's hard drive through your Web browser (if you allow) that enable us to recognize your browser and capture and remember certain information.</p>

        <p>5. Third Party Disclosure</p>

        <p>We do not sell, trade, or transfer your personally identifiable information to outside parties. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</p>

        <p>6. Your Rights</p>

        <p>Depending on your local laws, you may have certain rights regarding your personal data. These may include the right to access, correct, and delete your personal data and to object to processing of your data.</p>

        <p>7. Updates</p>

        <p>Our Privacy Policy may change from time to time, and all updates will be posted on this page.</p>

        <p>By using our site, you consent to our website's Privacy Policy.</p>

        <p>If you have any questions regarding this Privacy Policy, you may contact us.</p>

        <p>Last Updated: 06/20/2023</p>
    </div>
  )
}

export default PrivacyPolicy