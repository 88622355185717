import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import client from '../contentful.js';
import { Helmet } from 'react-helmet-async';
import '../CSS/layout.css';
import '../CSS/Blog.css'

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(date);
    return formattedDate;
};


const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [featured, setFeatured] = useState([]);
    const [search, setSearch] = useState('');
    const [filteredResults, setFilteredPosts] = useState([])
    const [searchIndex, setSearchIndex] = useState({});

    useEffect(() => {
        const fetchPosts = async () => {
            const entries = await client.getEntries({ 
                content_type: 'blogPost', 
                include: 2, 
                order: '-fields.publishedDate' 
            });
            const posts = entries.items;
            setPosts(posts);
            
            const featuredPosts = await client.getEntries({ 
                content_type: 'featuredPost', 
                include: 2 
            });
            setFeatured(featuredPosts.items);
      
            const searchIndex = {};

            for (const post of posts) {
                const tags = post.metadata.tags.map(tag => tag.sys.id.toLowerCase()); // Access tags under metadata
                const titleWords = post.fields.title.toLowerCase().split(' '); // Split title into words
            
                // Combine tags and title words into one array
                const words = [...tags, ...titleWords];
            
                for (const word of words) {
                    for (let i = 1; i <= word.length; i++) {
                        const substring = word.substring(0, i);
                        if (searchIndex[substring]) {
                            if (!searchIndex[substring].includes(post)) {
                                searchIndex[substring].push(post);
                            }
                        } else {
                            searchIndex[substring] = [post];
                        }
                    }
                }
            }   
            setSearchIndex(searchIndex);            
        };      
        fetchPosts();
    }, []);
    

const handleSearch = (event) => {
    const searchInput = event.target.value.toLowerCase();
    setSearch(searchInput);

    // Split searchInput into terms, and filter out any empty strings (i.e., "")
    const searchTerms = searchInput.split(' ').filter(term => term !== '');

    let results = [];

    for (let i = 0; i < searchTerms.length; i++) {
        const term = searchTerms[i];
        const termResults = searchIndex[term] || [];
        if (i === 0) {
            // For the first term, just take all the results
            results = termResults;
        } else {
            // For subsequent terms, take the intersection of the current results and the term results
            results = results.filter(result => termResults.includes(result));
        }
    }

    setFilteredPosts(results);
};
console.log(posts)
    return (
        <div className="blog">
                <Helmet>
                    <title>Gothic Getaways</title>
                    <meta name="description" content="The #1 Travel Site for haunted retreats, chilling tours, and other spine-tingling adventures" />
                    <link rel="canonical" href="https://www.gothicgetaways.com/" />
                    <meta property="og:title" content="Gothic Getaways" />
                    <meta property="og:description" content="The #1 Travel Site for haunted retreats, chilling tours, and other spine-tingling adventures" />
                    <meta property="og:url" content="https://www.gothicgetaways.com/" />
                    <meta property="og:type" content="website" />
                    {/* Assuming you have a default image for the blog page */}
                    <meta property="og:image" content="../Assets/Logo.png" />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="627"/>
                </Helmet>
            <div className="featured">
                <div className="featured_section_title">
                    {/* <h1>Featured</h1> */}
                </div>
                {featured.length > 0 && (
                    <Link to={`/blog-post/${featured[0].fields.featuredBlogPost.fields.slug}`} key={featured[0].fields.featuredBlogPost.sys.id}>

                        <div className="blog_preview featured_preview">
                            <img
                                className="featured_image"
                                src={featured[0].fields.featuredBlogPost.fields.featuredImage.fields.file.url}
                                alt={featured[0].fields.featuredBlogPost.fields.title}
                            />
                            <div className="featured_content">
                                {/* <div className="featured_author">
                                <div className="featured_author_image_wrapper">
                                    <img
                                    className="featured_author_image"
                                    src={featured[0].fields.featuredBlogPost.fields.author.fields.avatar.fields.file.url}
                                    alt={featured[0].fields.featuredBlogPost.fields.author}
                                    />
                                </div>
                                <p className="featured_author">{featured[0].fields.featuredBlogPost.fields.author.fields.name}</p>
                                </div> */}
                                <h1 className="featured_title">{featured[0].fields.featuredBlogPost.fields.title}</h1>
                                <div className="featured_subtitle">
                                <p>{featured[0].fields.featuredBlogPost.fields.shortDescription}</p>
                                </div>
                                <div className="featured_date">
                                <p>{formatDate(featured[0].fields.featuredBlogPost.fields.publishedDate)}</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                )}
            </div>

            <div className="search-bar">
                <input 
                    type="text" 
                    value={search} 
                    onChange={handleSearch} 
                    placeholder="Search articles..." 
                    className="search-input"
                />
                {filteredResults.length > 0 && (
                    <div className="search-results">
                        <ul>
                            {filteredResults.map((post) => (
                                <li key={post.sys.id}>
                                    <Link to={`/blog-post/${post.fields.slug}`} key={post.sys.id}>

                                        {post.fields.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            <div className="latest">
                <div className="latest_cards_container">
                {posts.slice(0, 6).map((post) => (
                        <Link to={`/blog-post/${post.fields.slug}`} key={post.sys.id}
                        className="latest_card_link">
                            <div className="latest_card">
                                <img
                                className="latest_card_image"
                                src={post.fields.featuredImage.fields.file.url}
                                alt={post.fields.title}
                                />
                                <div className="latest_card_content">
                                    <p className="latest_card_title">{post.fields.title}</p>
                                    <div className="latest_author">
                                        <div className="latest_author_image_wrapper">
                                            <img
                                            className="latest_author_image"
                                            src={post.fields.author.fields.avatar.fields.file.url}
                                            alt={post.fields.author}
                                            />
                                        </div>
                                        <p className="latest_author">
                                        {post.fields.author.fields.name}
                                        </p>

                                        <div className="latest_date">
                                            <p>{formatDate(post.fields.publishedDate)}</p>
                                        </div>          
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Blog;