import * as React from "react"
import "../CSS/InfoPages.css"

const Disclaimer = () => {
  return(
    <div className="disclaimer">
        <h1>Disclaimer</h1>
        <p>Welcome to our Paranormal Travel Blog! Our site is dedicated to those who harbor an unquenchable curiosity for the mysterious, the unexplained, and the downright spooky. We weave narratives around eerie legends, haunted destinations, and otherworldly phenomena across the globe.</p>

        <p>Before you embark on this paranormal journey with us, please take a moment to understand the following disclaimers:</p>

        <p>Fiction or Fact: The content on this site – including the stories, accounts, and descriptions of paranormal events and places – is based on local folklore, personal experiences, and hearsay. Some tales may be rooted in truth, while others may spring purely from imagination. As such, we cannot guarantee the factual accuracy or reliability of the information provided.</p>

        <p>Image Authenticity: The images accompanying our content are intended to provide a visual context for the stories. Some of them are illustrative and may not represent the actual appearance or condition of the destinations described. We strongly advise doing your own research before planning any travels based on our content.</p>

        <p>Travel Safety: If you decide to visit any of the locations mentioned on our site, please prioritize your safety. Remember that some places may be in remote or dangerous areas, or may be private property. Always respect local regulations and customs, and ensure you have the necessary permissions for access.</p>

        <p>Subjective Interpretation: The paranormal is a subject open to wide interpretation and skepticism. Our content reflects our own perspectives and experiences, which may not align with everyone's beliefs or views. We encourage open-mindedness and respect for different viewpoints.</p>

        <p>Legal Liability: The authors and administrators of this site are not liable for any actions taken, risks undertaken, or consequences faced by readers inspired by the content provided. Any actions you decide to take based on our content are entirely at your own risk and responsibility.</p>

        <p>Content Changes: We reserve the right to modify, update, or remove content at any time without prior notice.</p>

        <p>By using our site, you acknowledge and agree to these disclaimers. Enjoy your journey into the unknown – whether you're a believer, a skeptic, or simply a curious wanderer, we hope our tales offer you a thrilling escape from the mundane.</p>

        <p>Remember, it's a strange world out there. Happy exploring!</p>

        <p>Last Updated: 06/20/2023</p>
    </div>
  )
}

export default Disclaimer